import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutYears,
  filterOutCatsWithoutParents,
  shuffle
} from '../lib/helpers'
import ProjectPreviewList from '../components/project-preview-list'
import BookPreviewList from '../components/book-preview-list'
import TypesettingPreviewList from '../components/typesetting-preview-list'
import StudentWorkPreviewList from '../components/student-work-preview-list'
import FontPreviewList from '../components/font-preview-list'
import PresentationPreviewList from '../components/presentation-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
          metadata {
            dimensions {
              aspectRatio
            }
            lqip
          }
          _id
        }
  }
  query MakingPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      email
      linkedInLink
      author
      keywords
      ccLink
      ccImage {
        ...SanityImage
        alt
      }
      image {
        ...SanityImage
        alt
      }
    }

    cats: allSanityCategory(sort: {fields: [title], order: ASC}, filter: {title: {ne: null}, }) {
      edges {
        node {
          title
          slug {
            current
          }
          parents {
            id
          }
        }
      }
    }

    projects: allSanityOtherProjects(
      limit: 5
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null }, featured: { eq: "true" } }
    ) {
      edges {
        node {
          id
          publishedAt
          image {
            desktopImage {
              ...SanityImage
              alt
              caption
            }
            mobileImage {
              ...SanityImage
              alt
              caption
            }
          }
          images {
            ...SanityImage
            alt
            caption
          }
          title
          _rawDescription
          slug {
            current
          }
        }
      }
    }

    books: allSanityBook(
      limit: 15
      sort: { fields: [catalogNumber], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          coverSpread {
            ...SanityImage
            alt
            caption
          }
          mainImage {
            ...SanityImage
            alt
            caption
          }
          spreads {
            ...SanityImage
            alt
            caption
          }
          title
          _rawDescription
          slug {
            current
          }
        }
      }
    }

    fonts: allSanityFont(
      limit: 10
      sort: { fields: title, order: ASC }
      filter: { slug: { current: { ne: null } } }
    ) {
      edges {
        node {
          id
          images {
            desktopImage {
              ...SanityImage
              alt
              caption
            }
            mobileImage {
              ...SanityImage
              alt
              caption
            }
          }
          title
          _rawDescription
          slug {
            current
          }
        }
      }
    }

    studentWork: allSanityStudentWork(
      limit: 6
      sort: { fields: [year], order: DESC }
      filter: { slug: { current: { ne: null } }, year: { ne: null } }
    ) {
      edges {
        node {
          id
          year
          images {
            ...SanityImage
            alt
            caption
          }
          title
          webLink
          name
          slug {
            current
          }
        }
      }
    }

    presentations: allSanityPresentation(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      limit: 5
    ) {
      edges {
        node {
          id
          publishedAt
          image {
            desktopImage {
              ...SanityImage
              alt
              caption
            }
            mobileImage {
              ...SanityImage
              alt
              caption
            }
          }
          title
          slug {
            current
          }
          pdf {
            file {
              asset {
                url
              }
            }
          }
          webLink
          video {
            url
          }
          _rawDescription(resolveReferences: {maxDepth: 5})
          _rawExcerpt
        }
      }
    }

  }
`

const MakingPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []
  const bookNodes = (data || {}).books
    ? mapEdgesToNodes(data.books)
      .filter(filterOutDocsWithoutSlugs)
    : []
  const fontNodes = (data || {}).fonts
    ? mapEdgesToNodes(data.fonts)
      .filter(filterOutDocsWithoutSlugs)
    : []
  const studentWorkNodes = (data || {}).studentWork
    ? mapEdgesToNodes(data.studentWork)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsWithoutYears)
    : []
  const catNodes = (data || {}).cats
    ? mapEdgesToNodes(data.cats)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutCatsWithoutParents)
    : []
  const presentationNodes = (data || {}).presentations
    ? mapEdgesToNodes(data.presentations)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

    shuffle(bookNodes);

  return (
    <Layout catNodes={catNodes} title={site.title} email={site.email} footer={site.footer} instagramLink={site.instagramLink} facebookLink={site.facebookLink} twitter={site.twitter} linkedInLink={site.linkedInLink} ccImage={site.ccImage} ccLink={site.ccLink} >
      <SEO
        title={site.title}
        author={site.author}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>

        {bookNodes && (
          <TypesettingPreviewList
            title='Typesetting'
            nodes={bookNodes}
            browseMoreHref='/books/'
          />
        )}

        {bookNodes && (
          <BookPreviewList
            title='Book Covers'
            nodes={bookNodes}
            browseMoreHref='/books/'
          />
        )}

        {fontNodes && (
          <FontPreviewList
            title='Fonts'
            nodes={fontNodes}
            browseMoreHref='/fonts/'
          />
        )}

        {presentationNodes && (
          <PresentationPreviewList
            title='Presentations'
            nodes={presentationNodes}
            browseMoreHref='/presentations/'
          />
        )}

        {projectNodes && (
          <ProjectPreviewList
            title='Projects'
            nodes={projectNodes}
            browseMoreHref='/projects/'
          />
        )}

        {studentWorkNodes && (
          <StudentWorkPreviewList
            title='Student work'
            nodes={studentWorkNodes}
            browseMoreHref='/student-work/'
          />
        )}

      </Container>
    </Layout>
  )
}

export default MakingPage