import {format} from 'date-fns'
import React from 'react'
import {buildImageObj, getPresentationUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import '../styles/presentation-preview.css'

function PresentationPreview (props) {

  return (
        <>
        {props.image && props.image.mobileImage && (
          <div className="presentation-preview-item">
            <a href={getPresentationUrl(props.publishedAt, props.slug.current)}>
              <img
                src={imageUrlFor(buildImageObj(props.image.mobileImage))
                  .width(1000)
                  .height(Math.floor((1 / 1) * 1000))
                  .auto('format')
                  .url()}
                alt={props.image.mobileImage.alt}
                title={props.image.mobileImage.alt}
              />
              <div className="over">
                <h4>{props.title}</h4>
                <p className="date">{format(props.publishedAt, 'MMMM Do, YYYY')}</p>
              </div>
            </a>
          </div>
            )}
        </>

  )
}

export default PresentationPreview